import React, { useState, useEffect } from 'react';
import { Link, navigate } from "gatsby"

import Layout from "components/layout"
import Seo from "components/seo"


import GlobalFooter from "components/medangle/GlobalFooter"

function footer(props) {

return(
  <GlobalFooter/>
  );
}

  export default footer
  